// Body
$body-bg: white;

// Typography
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$Quiterios-darkGray: #323d48;
$Quiterios-gray: #f3f3f3;
$Quiterios-lightGray: #ccc;
$Quiterios-white: white;
$Quiterios-black: black;
$Quiterios-green: #009e32;
$Quiterios-red: red;

@mixin formatText($font_type, $font_size, $font_color, $text_transform, $cursor){
  font-family: 'Poppins', sans-serif;
  font-weight: $font_type;
  font-size: $font_size;
  color: $font_color;
  text-transform: $text_transform;
  cursor: $cursor;
}

