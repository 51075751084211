.register-form-content {
  .title-form {
    @include formatText(700, 18px, $Quiterios-black, uppercase, default);
  }

  .form-group {
    display: flex;
    flex-direction: column;
    // margin: 10px 0;
  }

  .input-group {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    background-color: $Quiterios-gray;
    border-radius: 0.5rem;
    border-color: transparent;
    height: 40px;
    width: 100%;
  }

  .form-label {
    @include formatText(400, 12px, $Quiterios-black, none, default);
  }

  .form-check-label {
    @include formatText(400, 12px, $Quiterios-black, none, default);
  }

  .form-check-label a {
    @include formatText(500, 12px, $Quiterios-black, none, pointer);
  }

  label.error {
    @include formatText(400, 12px, $Quiterios-red, none, default);
  }

  input[type="checkbox"]:checked {
    accent-color: $Quiterios-green;
  }

  .anchor-label {
    @include formatText(400, 12px, $Quiterios-black, none, pointer);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .form-control {
    background-color: $Quiterios-gray;
    border-radius: 0.5rem;
    border-color: transparent;
    height: 40px;
    padding: 0 10px;
    @include formatText(400, 12px, $Quiterios-black, none, text);

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 1px solid $Quiterios-darkGray;
    }
  }

  .label-input-simulate {
    width: 100%;
    background-color: $Quiterios-gray;
    border-radius: 0.5rem;
    border-color: transparent;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;

    @include formatText(400, 12px, #798384, lowercase, pointer);
  }

  .input-group-prepend .input-group-text {
    background-color: $Quiterios-gray;
    border: none;
    color:$Quiterios-darkGray;
  }

  .input-group-text {
    border-radius: 0.5rem;
  }

  .actions-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  .backButton {
    width: calc(50% - 20px);
    max-width: 250px;
    height: 40px;
    background-color: $Quiterios-darkGray;
    border: 2px solid $Quiterios-darkGray;
    border-radius: 0.5rem;
    @include formatText(700, 14px, $Quiterios-white, lowercase, pointer);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $Quiterios-white;
      color: $Quiterios-black;
    }
  }

  .submitButton {
    width: calc(50% - 20px);
    max-width: 250px;
    height: 40px;
    background-color: $Quiterios-green;
    border: 2px solid $Quiterios-green;
    border-radius: 0.5rem;
    @include formatText(700, 14px, $Quiterios-white, lowercase, pointer);
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.submitButton[disabled]):hover {
      background-color: $Quiterios-white;
      color: $Quiterios-black;
    }
  }

  .submitButton[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  @media only screen and (min-width: 1200px) {
    padding: 1rem 3rem 3rem;
    .form-group {
      max-width: 510px;
    }
  }

  @media only screen and (max-width: 1199.98px) {
    padding: 1rem 2rem 2rem;
  }
}
