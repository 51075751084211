.login-form-content {
  .title-form {
    @include formatText(700, 16px, $Quiterios-black, uppercase, default);
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  .actions-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  .form-check {
    display: flex;
    align-items: center;
    height: 20px;

    .form-check-input {
      cursor: pointer;
      margin-top: 0;

      &:checked {
        accent-color: $Quiterios-darkGray;
      }
    }
  }

  .form-label {
    @include formatText(400, 12px, $Quiterios-black, none, default);
  }

  .form-check-label {
    @include formatText(400, 12px, $Quiterios-black, none, pointer);
  }

  .anchor-label {
    @include formatText(400, 12px, $Quiterios-black, none, pointer);
    text-decoration: none;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .form-control {
    background-color: $Quiterios-gray;
    border-radius: 0.5rem;
    border-color: transparent;
    height: 40px;
    padding: 0 10px;
    @include formatText(400, 12px, $Quiterios-black, none, text);

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 1px solid $Quiterios-darkGray;
    }
  }

  .input-password {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .show-password {
    margin-right: 0;
  }

  .append-right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 0;
    background: #f3f3f3;
  }

  .submitButton {
    width: calc(50% - 20px);
    max-width: 250px;
    height: 40px;
    background-color: $Quiterios-green;
    border: 2px solid $Quiterios-green;
    border-radius: 0.5rem;
    @include formatText(700, 14px, $Quiterios-white, lowercase, pointer);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $Quiterios-white;
      color: $Quiterios-black;
    }
  }

  .new-account {
    width: calc(50% - 20px);
    max-width: 250px;
    height: 40px;
    background-color: $Quiterios-darkGray;
    border: 2px solid $Quiterios-darkGray;
    border-radius: 0.5rem;
    @include formatText(700, 14px, $Quiterios-white, lowercase, pointer);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $Quiterios-white;
      color: $Quiterios-black;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 1rem 3rem 3rem;
    .form-group {
      max-width: 510px;
    }
  }

  @media only screen and (max-width: 1199.98px) {
    padding: 1rem 2rem 2rem;
  }
}

