@import "resources/sass/frontend/_variables.scss";

#account-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-repeat: no-repeat;
  @import "../components/login-form";
  @import "../components/register-form";

  .show-password{
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 0;
    background: #f3f3f3;
  }


  @media only screen and (min-width: 1200px) {
    background-color: $Quiterios-gray;
    height:100%;
    #only-desktop {
      width: 100%;
    }

    #only-mobile {
      display: none;
    }

    .block-application-details {
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 0 3rem 3rem;

        .logo-apps-availables {
          width: 240px;
          height: 240px;
          margin-bottom: 2rem;
        }

        .name-client {
          @include formatText(300, 18px, $Quiterios-darkGray, uppercase, default);
          margin: 0;
        }

        .name-app {
          @include formatText(700, 33px, $Quiterios-darkGray, uppercase, default);
          margin: 0;
        }

        .desc {
          text-align: justify;
          margin: 40px 0 0 0;
          width: 80%;
          max-width: 300px;

          & > * {
            color: $Quiterios-darkGray;
          }

          h1 {
            font-size: 20px;
          }

          h2 {
            font-size: 19px;
          }

          h3 {
            font-size: 18px;
          }

          h4 {
            font-size: 17px;
          }

          h5 {
            font-size: 16px;
          }

          h6 {
            font-size: 15px;
          }

          p {
            font-size: 14px;
          }
        }

        .assembles-list {
          width: 100%;
          justify-content: flex-start;
          list-style: none;
          display: flex;
          padding: 0;
          margin-top: 20px;

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 15px;
          }

          .menu-item {
            @include formatText(700, 10px, $Quiterios-darkGray, uppercase, default);
          }
        }

        .mockup-mob {
          background-position: 100% 100%;
          background-size: contain;
          background-repeat: no-repeat;
          height: 80vh;
          max-height: 750px;
        }

        .app-quiterios {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          padding: 0;
        }
      }

    .block-all-applications {
      display: block;
      padding: 3rem;

      .info-block {
        max-width: 55%;

        h1 {
          @include formatText(700, 18px, $Quiterios-darkGray, none, default);
        }

        div {
          @include formatText(400, 16px, $Quiterios-darkGray, none, default);
          max-width: 641px;
          width: 100%;
        }
      }

      .all-apps-available {
        border-top: 1px solid $Quiterios-black;
        display: flex;
      }
    }

    .third-block {
      width: 100%;
      background-color: $Quiterios-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .body {
        width: 100%;
        max-width: 510px;
      }

      .logo-quiterios {
        width: 380px;
        object-fit: cover;
        padding: 3rem 3rem 1rem;
        display: block;
      }

      .logo-apps-availables, .desc-article {
        display: none;
      }

      .footer {
        margin: 0 0 3rem 0;
      }

      .footer-list {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 40px;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;

        li {
          text-align: center;
        }

        .item-name {
          @include formatText(500, 10px, $Quiterios-black, uppercase, pointer);
        }
      }
    }
  }

  @media only screen and (max-width: 1199.98px) {
    background-image: none !important;
    background-color: $Quiterios-white;
    justify-content: center;
    #only-desktop {
      display: none;
    }

    .all-apps-available {
      width: 100%;
      //height: 100vh;
      .options-to-see-apps {
        height: calc(100vh - 195px);
      }
    }

    .logo-quiterios {
      display: none;
    }

    .logo-apps-availables {
      display: block;
      max-width: 320px;
      object-fit: cover;
      padding: 2rem 2rem 1rem;
    }

    .desc-article {
      text-align: justify;
      padding: 1rem 2rem;

      & > * {
        color: $Quiterios-darkGray;
      }

      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 19px;
      }

      h3 {
        font-size: 18px;
      }

      h4 {
        font-size: 17px;
      }

      h5 {
        font-size: 16px;
      }

      h6 {
        font-size: 15px;
      }

      p {
        font-size: 14px;
      }
    }

    .footer {
      padding: 2rem 2rem 1rem;
    }

    .footer-list {
      list-style: none;
      padding: 0;
      margin: 10px 0;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        border-top: 1px solid $Quiterios-black;
      }

      .item-name {
        @include formatText(500, 12px, $Quiterios-black, uppercase, pointer);
      }
    }
  }
}
